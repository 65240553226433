import { useCallback, useEffect, useState } from 'react';
import { buildFormData, buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const useFetchCollection = _ref => {
  let {
    fetchCallback,
    setLoading,
    initialQueryParams
  } = _ref;
  const [items, setItems] = useState([]);
  const fetchItems = useErrorHandling(useCallback(async queryParams => {
    setLoading && setLoading(true);
    const allItems = await fetchCallback(queryParams);
    setItems(allItems);

    // returns the promise with data just in case we need it somewhere
    return allItems;
  }, [fetchCallback, setLoading]), useCallback(() => {
    setLoading && setLoading(false);
  }, [setLoading]));
  useEffect(() => {
    fetchItems(initialQueryParams || {});
  }, [fetchItems, initialQueryParams]);
  return [items, setItems, fetchItems];
};
const useCreate = _ref2 => {
  let {
    createCallback,
    setLoading,
    onError,
    onFinally
  } = _ref2;
  const createModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await createCallback();
    setLoading && setLoading(false);
    return result;
  }, [setLoading, createCallback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return createModel;
};

/**
 * The resulting method accepts an object with the formDataJson field and any other fields.
 * It turns the formDataJson into a real form data - formData.
 * 
 * { field1: 1, value1, ..., formDataJson } => callback({ field1: value 1, ..., formData })
 * 
 * @param {*} param0 
 * @returns 
 */
const usePostFormData = _ref3 => {
  let {
    callback,
    setLoading,
    onError,
    onFinally
  } = _ref3;
  const postFormData = useErrorHandling(useCallback(async paramsObject => {
    setLoading && setLoading(true);
    const {
      formDataJson
    } = paramsObject;
    const formData = buildFormData(formDataJson);
    const result = await callback({
      ...paramsObject,
      formData
    });
    setLoading && setLoading(false);
    return result;
  }, [setLoading, callback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return postFormData;
};
const useUpdate = _ref4 => {
  let {
    updateCallback,
    setLoading,
    onError,
    onFinally
  } = _ref4;
  const updateModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await updateCallback();
    setLoading && setLoading(false);
    return result;
  }, [setLoading, updateCallback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return updateModel;
};
const useDelete = _ref5 => {
  let {
    deleteCallback,
    setLoading,
    onError,
    onFinally
  } = _ref5;
  const deleteModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await deleteCallback();
    setLoading && setLoading(false);
    return result;
  }, [setLoading, deleteCallback]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return deleteModel;
};
const serviceMethods = {
  useFetchCollection,
  useUpdate,
  useDelete,
  useCreate,
  usePostFormData
};
export default serviceMethods;