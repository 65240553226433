import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Table } from '@rd-web-markets/shared/dist/util';
import { SHOW_DOWNLOAD_TEMPLATE_BUTTON } from '@rd-web-markets/market/dist/constants';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { UploadButton } from '@rd-web-markets/shared/dist/util/buttons/UploadButton';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ongoingTemplateImportService from '@services/claim_groups/ongoing_template_import.service';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service'
import { generateProjectQuestionnaire } from '@rd-web-markets/be/dist/services/docx_generators/project_questionnair_patcher.service';
import BackgroundJobStatusDisplay from '@rd-web-markets/shared/dist/jobs/BackgroundJobStatusDisplay';

const OngoingProjects = ({ claimGroup, accountType }) => {
  const hiddenFileInputNonIt = useRef(null);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [reports] = claimProjectReportsListsService.useFetchAll({
    accountType,
    claimGroupId: claimGroup.id,
    setLoading
  })

  const generateOngoingProjectQuestionnaires = async (document_type) => {
    const leadConsultant = claimGroup.consultant_roles.find(role => role.role === 'claim_group_access_lead_consultant');
    const companyName = claimGroup.company.abbreviated_name;

    reports.forEach(async (report) => {
      if(report.it_type === document_type) {
        
        await generateProjectQuestionnaire(
          {
            ...report,
            companyName,
            leadConsultant: leadConsultant.user ? leadConsultant.user.name : ''
          },
          document_type === 'not_it' ? 'ongoing_non_it_project_document' : 'ongoing_it_project_document');
      }
    })
  }

  const handleClick = (type) => {
    if (type === 'non_it') {
      hiddenFileInputNonIt.current.click();
    } else {
      hiddenFileInput.current.click();
    }
  };

  const uploadOngoingTemplate = async (event, type) => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    setLoading(true);
    try {
      await ongoingTemplateImportService.create(claimGroup.id, fd, type);
    } catch(error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
    hiddenFileInput.current.value = '';
  }

  const importTableHeaders = [
      { text: t('date'), },
      { text: t('uploaded_by'), },
      { text: t('type'), },
      { text: '' },
  ];

  const sortedProjectTemplateImports = claimGroup.ongoing_template_imports.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1)

  const importTableRows = () => {
    return sortedProjectTemplateImports.map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.uploaded_by,
        im.import_type,
        <a 
          href={im.download_link.url} target="_blank" rel="noreferrer"
        >
          {im.download_link.filename}
        </a>  
      ]
    }));
  }


  return (
    <>
      <Row>
        <Col md={12} className="mt-3" >
          <BackgroundJobStatusDisplay
            jobStatusObject={sortedProjectTemplateImports[0]}
            errorsArrayPropertyName='import_messages'
          />
        </Col>
      </Row>
      <Row>
        <Col md={SHOW_DOWNLOAD_TEMPLATE_BUTTON ? 6 : 12} className="text-center">
          <UploadButton
            id="upload-cost-template-button"
            isImmutableUnderReview={true}
            loading={loading}
            onClick={() => handleClick('it')}
            text='It Projects'
          />
          <>
            <span className="mr-4"></span>
            <UploadButton
              id="upload-cost-template-with-projects-button"
              isImmutableUnderReview={true}
              loading={loading}
              onClick={() => handleClick('non_it')}
              text='Non It'
            />
          </>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInput}
            onChange={(e) => uploadOngoingTemplate(e, 'it')}
          ></input>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInputNonIt}
            onChange={(e) => uploadOngoingTemplate(e, 'non_it')}
          ></input>
        </Col>
        <Col md={6}>
          <div className="d-flex flex-column">
            <Button
              onClick={() => generateOngoingProjectQuestionnaires('default')}
              className='ml-auto mr-auto mb-2 w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_ongoing_it_project_questionnaire_file')}
            </Button>
            <Button
              onClick={() => generateOngoingProjectQuestionnaires('not_it')}
              className='ml-auto mr-auto w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_ongoing_non_it_project_questionnaire_file')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} className='text-center'>
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </>
  )
}

export default OngoingProjects