import { buildService } from '@rd-web-markets/shared/dist/services/service'
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods'
import { useCallback } from 'react'

const api = buildService('/claim_groups/{id}/project_template_imports', { only: [ 'all']}, { only: ['postFormData'] })

const useCreateProjectImport = ({ claimGroupId }) => {
  //accepts an object with the formDataJson field and any other fields.
  const postFormData = serviceMethods.usePostFormData({
    callback: useCallback(
      async ({ formData, type }) => {
        return  await api.postFormData(claimGroupId, formData, { type })

    }, [claimGroupId])
  })

  return postFormData
}

const projectTemplateImportService = {
  useCreateProjectImport
}

export default projectTemplateImportService
